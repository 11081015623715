import getApiClient from '../../../../shared/api/api-client/getApiClient';
import ApiResultStatus from '../../../../shared/api/api-result/ApiResultStatus';

export default async function checkForUpdates(): Promise<
  | {
      errorMessage: string;
      status: ApiResultStatus.ERROR;
    }
  | {
      isUpdateAvailable: boolean;
      status: ApiResultStatus.OK;
    }
> {
  const client = getApiClient();

  const response = await client.get('/updates/check');

  if (!response.ok) {
    return {
      errorMessage: await response.errorMessage(),
      status: ApiResultStatus.ERROR,
    };
  }

  const json = await response.json<{
    is_available_updates: boolean;
  }>();

  return {
    isUpdateAvailable: json.is_available_updates,
    status: ApiResultStatus.OK,
  };
}
