enum ReportSubjectType {
  AFFILIATE_NETWORK = 'affiliate-network',
  CAMPAIGN = 'campaign',
  LANDING = 'landing',
  OFFER = 'offer',
  ROTATION = 'rotation',
  TRAFFIC_SOURCE = 'traffic-source',
}

export default ReportSubjectType;
