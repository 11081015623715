import type CrudAction from '../crud/CrudAction';

import type { RouteRecordRaw } from 'vue-router';

import CrudActionType from '../crud/CrudActionType';

const RotationsPage = async () => import('../../pages/RotationsPage.vue');
const RotationFormPage = async () => import('../../pages/RotationFormPage.vue');

export default function getRotationsRoutes(): RouteRecordRaw[] {
  return [
    {
      component: RotationsPage,
      meta: {
        title: 'Rotations',
        useIn: ['navigation-tabs'],
      },
      name: 'rotations',
      path: '/rotations',
    },
    {
      component: RotationFormPage,
      meta: {
        title: 'Create rotation',
      },
      name: 'create-rotation',
      path: '/rotations/create',
      props: (): {
        action: CrudAction;
      } => ({
        action: {
          type: CrudActionType.CREATE,
        },
      }),
    },
    {
      component: RotationFormPage,
      meta: {
        title: 'Edit rotation',
      },
      name: 'edit-rotation',
      path: '/rotations/edit/:id',
      props: (
        route,
      ): {
        action: CrudAction;
      } => ({
        action: {
          id: route.params.id as string,
          type: CrudActionType.EDIT,
        },
      }),
    },
    {
      component: RotationFormPage,
      meta: {
        title: 'Clone rotation',
      },
      name: 'clone-rotation',
      path: '/rotations/clone/:id',
      props: (
        route,
      ): {
        action: CrudAction;
      } => ({
        action: {
          id: route.params.id as string,
          type: CrudActionType.CLONE,
        },
      }),
    },
  ];
}
