import { defineStore } from 'pinia';

type State = {
  error: string | null;
};

export default defineStore('authenticationModalError', {
  actions: {
    clearError() {
      this.setError(null);
    },
    getErrorAndClear() {
      const { error } = this;

      this.clearError();

      return error;
    },
    setError(error: string | null) {
      this.error = error;
    },
  },
  state: (): State => ({
    error: null,
  }),
});
