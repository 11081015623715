enum ApiResultStatus {
  ACCESS_DENIED = 'access-denied',
  CANCELLED = 'cancelled',
  ERROR = 'error',
  LICENSE_EXPIRED = 'license-expired',
  NOT_FOUND = 'not-found',
  OK = 'ok',
  PARTIAL_ERROR = 'partial_error',
}

export default ApiResultStatus;
