import type { RouteRecordRaw } from 'vue-router';

import CrudActionType from '../crud/CrudActionType';

const UsersPage = async () => import('../../pages/UsersPage.vue');
const UserFormPage = async () => import('../../pages/UserFormPage.vue');

export default function getUsersRoutes(): RouteRecordRaw[] {
  return [
    {
      component: UsersPage,
      meta: {
        title: 'Users',
        useIn: ['navigation-tabs'],
      },
      name: 'users',
      path: '/users',
    },
    {
      component: UserFormPage,
      meta: {
        title: 'Create user',
      },
      name: 'create-user',
      path: '/users/create',
      props: {
        action: {
          type: CrudActionType.CREATE,
        },
      },
    },
    {
      component: UserFormPage,
      meta: {
        title: 'Edit user',
      },
      name: 'edit-user',
      path: '/users/edit/:id',
      props: (route) => ({
        action: {
          id: route.params.id,
          type: CrudActionType.EDIT,
        },
      }),
    },
    {
      component: UserFormPage,
      meta: {
        title: 'Clone user',
      },
      name: 'clone-user',
      path: '/users/clone/:id',
      props: (route) => ({
        action: {
          id: route.params.id,
          type: CrudActionType.CLONE,
        },
      }),
    },
  ];
}
