import { z } from 'zod';

export default function useCache<T extends z.ZodType<unknown>>(key: string, version: string, valueSchema: T) {
  const schema = z.object({
    value: valueSchema,
    version: z.literal(version),
  });

  const getValue = (): z.infer<T> | null => {
    const value = localStorage.getItem(key);

    if (value === null) {
      return null;
    }

    try {
      const parsingResult = schema.safeParse(JSON.parse(value));

      if (!parsingResult.success) {
        return null;
      }

      return parsingResult.data.value satisfies z.infer<T>;
    } catch {
      return null;
    }
  };

  const setValue = (value: z.infer<T>): void => {
    globalThis.localStorage.setItem(
      key,
      JSON.stringify({
        value,
        version,
      }),
    );
  };

  return {
    getValue,
    setValue,
  };
}
