import type SignInRequestDto from './dto/SignInRequestDto';

import extractTextFromHtmlString from '../../../lib/sanitize-html/extractTextFromHtmlString';
import getApiClient from '../../../shared/api/api-client/getApiClient';
import ApiResultStatus from '../../../shared/api/api-result/ApiResultStatus';
import extractErrorMessageFromLicenseExpiringErrorResponseOriginalData from '../../license/expiring/extractErrorMessageFromLicenseExpiringErrorResponseOriginalData';
import extractTrackerIdFromLicenseExpiringErrorResponseOriginalData from '../../license/expiring/extractTrackerIdFromLicenseExpiringErrorResponseOriginalData';

const UNAUTHORIZED_HTTP_CODE = 401;
const LICENSE_EXPIRED_HTTP_CODE = 402;

const ACCESS_DENIED_ERROR = 'Wrong username or password';

const LICENSE_NOT_FOUND_ERROR_MESSAGE_TEMPLATE = `
License not found

Tracker id: %trackerId%

Please enter your tracker id into your Binom v2 license at [binom.org](https://binom.org/login)
`;

const formatLinksInErrorMessage = (errorMessage: string) =>
  errorMessage.replaceAll(/(https?:\/\/[^\n ]+)/g, '[$1]($1)');

const replaceSingleNewLinesWithDouble = (errorMessage: string) =>
  errorMessage.replaceAll(/(?<!\n|^)\n(?!\n|$)/gm, '\n\n');

const prepareErrorMessage = (errorMessage: string) =>
  formatLinksInErrorMessage(replaceSingleNewLinesWithDouble(extractTextFromHtmlString(errorMessage)));

export default async function signIn(
  username: string,
  password: string,
): Promise<
  | {
      errorMessage: string;
      status: ApiResultStatus.ACCESS_DENIED;
    }
  | {
      errorMessage: string;
      status: ApiResultStatus.ERROR;
    }
  | {
      errorMessage: string;
      status: ApiResultStatus.LICENSE_EXPIRED;
    }
  | {
      status: ApiResultStatus.OK;
    }
> {
  const client = getApiClient();

  const response = await client.post('/api/user/sign_in', {
    json: {
      login: username,
      password,
    } satisfies SignInRequestDto,
  });

  if (response.ok) {
    return {
      status: ApiResultStatus.OK,
    };
  }

  if (response.status === UNAUTHORIZED_HTTP_CODE) {
    return {
      errorMessage: ACCESS_DENIED_ERROR,
      status: ApiResultStatus.ACCESS_DENIED,
    };
  }

  if (response.status === LICENSE_EXPIRED_HTTP_CODE) {
    const json = await response.json();

    const errorMessage = extractErrorMessageFromLicenseExpiringErrorResponseOriginalData(json) ?? '';
    const trackerId = extractTrackerIdFromLicenseExpiringErrorResponseOriginalData(json);

    if (errorMessage !== 'License not found') {
      return {
        errorMessage: prepareErrorMessage(`${errorMessage}\n\nTracker id: ${trackerId}`),
        status: ApiResultStatus.LICENSE_EXPIRED,
      };
    }

    return {
      errorMessage: LICENSE_NOT_FOUND_ERROR_MESSAGE_TEMPLATE.replace('%trackerId%', trackerId ?? ''),
      status: ApiResultStatus.LICENSE_EXPIRED,
    };
  }

  const errorMessage = await response.errorMessage();

  return {
    errorMessage: prepareErrorMessage(errorMessage),
    status: ApiResultStatus.ERROR,
  };
}
