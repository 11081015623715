import { ref } from 'vue';
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { useRoute } from 'vue-router';

import sleep from '../../../lib/awaiters/sleep';
import onBeforeEachOnce from '../../../lib/router/onBeforeEachOnce';
import RouteName from '../../../router/RouteName';
import ApiResultStatus from '../../../shared/api/api-result/ApiResultStatus';
import getIsTrackerInDemoMode from '../../demo-mode/api/getIsTrackerInDemoMode';
import signInAsDemo from '../../demo-mode/signInAsDemo';
import onGotLicenseExpiredApiResponse from '../../license/expiring/onGotLicenseExpiredApiResponse';
import getCurrentUserIdAndRoleDeduplicated from '../api/getCurrentUserIdAndRoleDeduplicated';
import useAuthenticationModalErrorStore from '../authentication-modal/useAuthenticationModalErrorStore';
import getReturnPathForAuthentication from './getReturnPathForAuthentication';
import onGotUnauthorizedApiResponse from './onGotUnauthorizedApiResponse';
import useRedirectToAuthenticationPage from './useRedirectToAuthenticationPage';

const onUserIdSuccessfullyLoaded = (to: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (to.name !== RouteName.AUTHENTICATION) {
    next();
    return;
  }

  next({
    name: 'campaigns',
  });
};

enum GuardState {
  INITIAL = 'initial',
  PASSED = 'passed',
  PASSING = 'passing',
}

export default function useAuthenticationAndLicenseGuard() {
  const route = useRoute();

  const guardState = ref<GuardState>(GuardState.INITIAL);

  const authenticationModalErrorStore = useAuthenticationModalErrorStore();

  const onGotLicenseExpiredError = (to: RouteLocationNormalized, next: NavigationGuardNext, errorMessage: string) => {
    if (to.name === RouteName.AUTHENTICATION && authenticationModalErrorStore.error === errorMessage) {
      next();
      return;
    }

    authenticationModalErrorStore.setError(errorMessage);

    next({
      name: RouteName.AUTHENTICATION,
      query: {
        returnTo: to.query.returnTo,
      },
    });
  };

  const onGotNotAuthorizedError = async (to: RouteLocationNormalized, next: NavigationGuardNext) => {
    if (to.name === RouteName.AUTHENTICATION) {
      next({
        name: RouteName.AUTHENTICATION,
        query: {
          returnTo: to.query.returnTo,
        },
      });
      return;
    }

    const isTrackerInDemoMode = await getIsTrackerInDemoMode();

    if (!isTrackerInDemoMode) {
      next({
        name: RouteName.AUTHENTICATION,
        query: {
          returnTo: to.query.returnTo ?? getReturnPathForAuthentication(route.fullPath),
        },
      });
      return;
    }

    const signingInAsDemoResult = await signInAsDemo();

    if (signingInAsDemoResult.status === ApiResultStatus.OK) {
      next();
      return;
    }

    next({
      name: RouteName.AUTHENTICATION,
      query: {
        returnTo: to.query.returnTo ?? getReturnPathForAuthentication(route.fullPath),
      },
    });
  };

  onBeforeEachOnce(
    async (to, _, next) => {
      const result = await getCurrentUserIdAndRoleDeduplicated();

      if (result.status === ApiResultStatus.OK) {
        return onUserIdSuccessfullyLoaded(to, next);
      }

      if (result.status === ApiResultStatus.LICENSE_EXPIRED) {
        return onGotLicenseExpiredError(to, next, result.errorMessage);
      }

      return onGotNotAuthorizedError(to, next);
    },
    () => {
      guardState.value = GuardState.PASSING;
    },
    async () => {
      // костыльным образом ждём, когда произойдет редирект
      // иначе хендлеры onGotUnauthorizedApiResponse и onGotLicenseExpiredApiResponse
      // выполнятся с guardState.value = GuardState.PASSED, при этом значение route.name не успеет поменяться
      await sleep();

      guardState.value = GuardState.PASSED;
    },
  );

  const redirectToAuthenticationPage = useRedirectToAuthenticationPage();

  onGotUnauthorizedApiResponse(async () => {
    if (guardState.value !== GuardState.PASSED) {
      return;
    }

    if (route.name === RouteName.AUTHENTICATION) {
      return;
    }

    const isTrackerInDemoMode = await getIsTrackerInDemoMode();

    if (!isTrackerInDemoMode) {
      await redirectToAuthenticationPage({
        returnTo: getReturnPathForAuthentication(route.fullPath),
      });
      return;
    }

    const signingInAsDemoResult = await signInAsDemo();

    if (signingInAsDemoResult.status === ApiResultStatus.OK) {
      return;
    }

    await redirectToAuthenticationPage({
      returnTo: getReturnPathForAuthentication(route.fullPath),
    });
  });

  onGotLicenseExpiredApiResponse(async ({ errorMessage }) => {
    if (guardState.value !== GuardState.PASSED) {
      return;
    }

    if (route.name === RouteName.AUTHENTICATION && authenticationModalErrorStore.error === errorMessage) {
      return;
    }

    authenticationModalErrorStore.setError(errorMessage);

    await redirectToAuthenticationPage({
      method: route.name === RouteName.AUTHENTICATION ? 'replace' : 'push',
      returnTo: route.query.returnTo,
    });
  });
}
