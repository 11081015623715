import type CrudAction from '../crud/CrudAction';

import type { RouteRecordRaw } from 'vue-router';

import CrudActionType from '../crud/CrudActionType';

const OffersPage = async () => import('../../pages/OffersPage.vue');
const OffersMassEditModal = async () => import('./offers-mass-edit-modal/OffersMassEditModal.vue');

export default function getOffersRoutes(): RouteRecordRaw[] {
  return [
    {
      children: [
        {
          component: OffersMassEditModal,
          name: 'edit-many-offers',
          path: String.raw`edit/mass/:ids(\d+)+`,
          props: (route) => ({
            offerIds: route.params.ids,
          }),
        },
      ],
      component: OffersPage,
      meta: {
        title: 'Offers',
        useIn: ['navigation-tabs'],
      },
      name: 'offers',
      path: '/offers',
      props: () => ({
        crudAction: null,
      }),
    },
    {
      component: OffersPage,
      name: 'create-offer',
      path: '/offers/create',
      props: (): {
        crudAction: CrudAction;
      } => ({
        crudAction: {
          type: CrudActionType.CREATE,
        },
      }),
    },
    {
      component: OffersPage,
      name: 'edit-offer',
      path: '/offers/edit/:id',
      props: (
        route,
      ): {
        crudAction: CrudAction;
      } => ({
        crudAction: {
          id: route.params.id as string,
          type: CrudActionType.EDIT,
        },
      }),
    },
    {
      component: OffersPage,
      name: 'clone-offer',
      path: '/offers/clone/:id',
      props: (
        route,
      ): {
        crudAction: CrudAction;
      } => ({
        crudAction: {
          id: route.params.id as string,
          type: CrudActionType.CLONE,
        },
      }),
    },
  ];
}
