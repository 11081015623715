import type { RouteRecordRaw } from 'vue-router';

import getMonitorForDevelopersRoutes from './developer/getMonitorForDevelopersRoutes';

const MonitorPage = async () => import('../../pages/MonitorPage.vue');

export default function getMonitorRoutes(): RouteRecordRaw[] {
  return [
    {
      component: MonitorPage,
      name: 'monitor',
      path: '/monitor',
    },
    ...getMonitorForDevelopersRoutes(),
  ];
}
