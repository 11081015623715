import type MayBePromise from '../types/MayBePromise';

import { defineStore } from 'pinia';
import type { UnwrapRef } from 'vue';

import dayjs from 'dayjs';

import sleep from '../awaiters/sleep';
import clone from '../clone';
import DefaultLogger from '../debug/logger/DefaultLogger';

function makeExpiresAfter() {
  return dayjs().add(1, 'hour').unix();
}

function isExpired(expiresAfter: number) {
  return dayjs().isAfter(dayjs.unix(expiresAfter));
}

const baseCacheKey = 'cached-data';

type StoredItem<T> = {
  data: T;
  expiresAfter: number;
};

function getCachedData<T>(key: string): StoredItem<T> | null {
  const readData = localStorage.getItem(`${baseCacheKey}.${key}`);

  if (readData === null) {
    return null;
  }

  return JSON.parse(readData) as StoredItem<T>;
}

function cacheData<T>(key: string, resultData: T) {
  localStorage.setItem(
    `${baseCacheKey}.${key}`,
    JSON.stringify({
      data: clone(resultData),
      expiresAfter: makeExpiresAfter(),
    }),
  );
}

type OnLoaded = () => void;

type State<T> = {
  isDataLoaded: boolean;
  isDataReallyLoading: boolean;
  isDataVisuallyLoading: boolean;
  isDelayedLoadingStopped: boolean;
  loadedData: T;
  onLoadedHandlers: OnLoaded[];
};

export default function makeStoreForLoadingDataAndCachingIt<T, U extends unknown[]>({
  checkIfShouldRefreshDataWhenItCachedAndNotExpired = () => true,
  delayBeforeLoadingActualData = 1000,
  getInitialData,
  loadData,
  storeKey,
}: {
  checkIfShouldRefreshDataWhenItCachedAndNotExpired?: (cachedData: T) => boolean;
  delayBeforeLoadingActualData?: number;
  getInitialData: () => T;
  loadData: (existingData: T, ...args: [...U]) => Promise<T | undefined>;
  storeKey: string;
}) {
  return defineStore(storeKey, {
    actions: {
      callOnLoadedHandlers() {
        const handlers = [...this.onLoadedHandlers];

        this.onLoadedHandlers = [];

        for (const handler of handlers) {
          handler();
        }
      },
      async loadData(onLoaded?: OnLoaded, ...args: [...U]) {
        if (onLoaded) {
          this.onLoadedHandlers.push(onLoaded);
        }

        if (this.isDataReallyLoading) {
          return;
        }

        this.isDataReallyLoading = true;

        await this.readCache({
          onCacheExpired: async (cachedData) => {
            this.writeDataToState(cachedData);
            await this.loadDataAndWriteToState(...args);
          },
          onLoadCachedValue: async (cachedData) => {
            this.writeDataToState(cachedData);

            const shouldRefreshData = checkIfShouldRefreshDataWhenItCachedAndNotExpired(cachedData);

            if (!shouldRefreshData) {
              return;
            }

            await this.loadDataFewMomentsLaterAndWriteToState(delayBeforeLoadingActualData, ...args);
          },
          onNotExist: async () => {
            this.isDataVisuallyLoading = true;
            await this.loadDataAndWriteToState(...args);
            this.isDataVisuallyLoading = false;
          },
        });

        this.isDataReallyLoading = false;
        this.callOnLoadedHandlers();
      },
      async loadDataAndWriteToState(...args: [...U]) {
        try {
          const loadedData = await loadData(this.loadedData as T, ...args);

          if (loadedData === undefined) {
            return;
          }

          this.updateData(loadedData);

          this.isDataLoaded = true;
        } catch (error) {
          DefaultLogger.writeError(error);
        }
      },
      async loadDataFewMomentsLaterAndWriteToState(timeout: number, ...args: [...U]) {
        this.isDelayedLoadingStopped = false;

        await sleep(timeout);

        if (this.isDelayedLoadingStopped) {
          return;
        }

        try {
          const loadedData = await loadData(this.loadedData as T, ...args);

          if (loadedData === undefined) {
            return;
          }

          this.updateData(loadedData);

          this.isDataLoaded = true;
        } catch (error) {
          DefaultLogger.writeError(error);
        }
      },
      async loadDataImmediately(onLoaded?: OnLoaded, ...args: [...U]) {
        if (this.isDataLoaded) {
          return;
        }

        this.stopDelayed();

        this.isDataReallyLoading = true;

        await this.loadDataAndWriteToState(...args);
      },
      async readCache({
        onCacheExpired,
        onLoadCachedValue,
        onNotExist,
      }: {
        onCacheExpired?: (cachedData: T) => MayBePromise<void>;
        onLoadCachedValue?: (cachedData: T) => MayBePromise<void>;
        onNotExist?: () => MayBePromise<void>;
      }) {
        const cached = getCachedData<T>(storeKey);
        if (!cached) {
          await onNotExist?.();
          return;
        }
        if (isExpired(cached.expiresAfter)) {
          await onCacheExpired?.(cached.data);
          return;
        }
        await onLoadCachedValue?.(cached.data);
      },
      async reloadDataImmediately(onLoaded?: OnLoaded, ...args: [...U]) {
        this.stopDelayed();

        this.isDataReallyLoading = true;

        await this.loadDataAndWriteToState(...args);
      },
      stopDelayed() {
        this.isDelayedLoadingStopped = true;
      },
      updateData(data: T) {
        this.writeDataToState(data);

        cacheData(storeKey, data);
      },
      writeDataToState(data: T) {
        this.loadedData = data as UnwrapRef<T>;
      },
    },
    state: (): State<T> => ({
      isDataLoaded: false,
      isDataReallyLoading: false,
      isDataVisuallyLoading: false,
      isDelayedLoadingStopped: false,
      loadedData: getInitialData(),
      onLoadedHandlers: [],
    }),
  });
}
