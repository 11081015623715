<template>
  <teleport to="#modals">
    <div v-if="navigationProgressStore.isPending" :class="style.progressBar">
      <div :class="style.progressBarBar" :style="progressBarBarStyle" />
    </div>
  </teleport>
</template>

<script lang="ts" setup>
  import { computed, useCssModule } from 'vue';
  import { useRouter } from 'vue-router';

  import { clamp } from 'lodash-es';

  import useNavigationProgressStore from './useNavigationProgressStore';

  const navigationProgressStore = useNavigationProgressStore();

  const router = useRouter();

  router.beforeEach(() => {
    void navigationProgressStore.start();
  });

  router.beforeResolve(() => {
    void navigationProgressStore.stop();
  });

  const progressBarBarStyle = computed(() => ({
    transform: `scaleX(${clamp(navigationProgressStore.progress, 0, 1)})`,
  }));

  const style = useCssModule();
</script>

<style lang="scss" module>
  .progressBar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 2px;

    background: transparent;

    z-index: 2;
  }

  .progressBarBar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ff9800;
    transform: scaleX(0);
    transform-origin: left;
  }
</style>
