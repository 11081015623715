import type { RouteRecordRaw } from 'vue-router';

import RouteName from '../../router/RouteName';

const AuthenticationPage = async () => import('../../pages/AuthenticationPage.vue');

export default function getAuthenticationRoutes(): RouteRecordRaw[] {
  return [
    {
      component: AuthenticationPage,
      meta: {
        title: 'Login',
      },
      name: RouteName.AUTHENTICATION,
      path: '/login',
    },
  ];
}
