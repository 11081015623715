import type { LocationQueryValueRaw } from 'vue-router';
import { useRoute, useRouter } from 'vue-router';

import RouteName from '../../../router/RouteName';

export default function useRedirectToAuthenticationPage() {
  const route = useRoute();

  const router = useRouter();

  return async ({
    method = 'push',
    returnTo = null,
  }: {
    method?: 'push' | 'replace';
    returnTo?: LocationQueryValueRaw | LocationQueryValueRaw[];
  } = {}) => {
    if (route.name === RouteName.AUTHENTICATION) {
      return;
    }

    const query: Record<string, LocationQueryValueRaw | LocationQueryValueRaw[]> = {};

    if (returnTo != null && returnTo !== '') {
      query.returnTo = returnTo;
    }

    await router[method]({
      name: RouteName.AUTHENTICATION,
      query,
    });
  };
}
