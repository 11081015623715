import { defineStore } from 'pinia';

type State = {
  errorMessage?: string;
  isEnabled: boolean;
};
export default defineStore('monitorObserverStore', {
  actions: {
    setIsObserverEnabled(isObserverEnabled: boolean) {
      this.isEnabled = isObserverEnabled;
    },
  },
  state: (): State => ({
    errorMessage: 'Monitor and updating are not working. Please contact our support.',
    isEnabled: true,
  }),
});
