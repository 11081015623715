import getApiClient from '../../../shared/api/api-client/getApiClient';
import ApiResultStatus from '../../../shared/api/api-result/ApiResultStatus';

export default async function getIsHideProfit(userId: string): Promise<
  | {
      errorMessage: string;
      status: ApiResultStatus.ERROR;
    }
  | {
      isHideProfit: boolean;
      status: ApiResultStatus.OK;
    }
> {
  const client = getApiClient();

  const response = await client.get(`/api/user/${userId}/hide_profit`);

  if (!response.ok) {
    return {
      errorMessage: await response.errorMessage(),
      status: ApiResultStatus.ERROR,
    };
  }

  const json = await response.json<{
    hideProfit: boolean;
  }>();

  return {
    isHideProfit: json.hideProfit,
    status: ApiResultStatus.OK,
  };
}
