import type CrudAction from '../crud/CrudAction';

import type { RouteRecordRaw } from 'vue-router';

import CrudActionType from '../crud/CrudActionType';

const AffiliateNetworksPage = async () => import('../../pages/AffiliateNetworksPage.vue');

export default function getAffiliateNetworksRoutes(): RouteRecordRaw[] {
  return [
    {
      component: AffiliateNetworksPage,
      meta: {
        tabTitle: 'Aff. networks',
        title: 'Affiliate Networks',
        useIn: ['navigation-tabs'],
      },
      name: 'affiliate-networks',
      path: '/affiliate-networks',
      props: () => ({
        crudAction: null,
      }),
    },
    {
      component: AffiliateNetworksPage,
      name: 'create-affiliate-network',
      path: '/affiliate-networks/create',
      props: (): {
        crudAction: CrudAction;
      } => ({
        crudAction: {
          type: CrudActionType.CREATE,
        },
      }),
    },
    {
      component: AffiliateNetworksPage,
      name: 'edit-affiliate-network',
      path: '/affiliate-networks/edit/:id',
      props: (
        route,
      ): {
        crudAction: CrudAction;
      } => ({
        crudAction: {
          id: route.params.id as string,
          type: CrudActionType.EDIT,
        },
      }),
    },
    {
      component: AffiliateNetworksPage,
      name: 'clone-affiliate-network',
      path: '/affiliate-networks/clone/:id',
      props: (
        route,
      ): {
        crudAction: CrudAction;
      } => ({
        crudAction: {
          id: route.params.id as string,
          type: CrudActionType.CLONE,
        },
      }),
    },
  ];
}
