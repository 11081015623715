enum RouteName {
  AUTHENTICATION = 'authentication',
  CREATE_DIRECTORY = 'create-directory',
  CREATE_TEXT_FILE = 'create-text-file',
  EDIT_TEXT_FILE_CONTENT = 'edit-text-file-content',
  FILES = 'files',
  RENAME_FILE_OR_DIRECTORY = 'rename-file-or-directory',
}

export default RouteName;
