import type DebugSettings from './DebugSettings';

import { defineStore } from 'pinia';
import { computed } from 'vue';

import getInitialDebugSettings from './getInitialDebugSettings';
import readDebugSettingsFieldOrGetInitialValue from './readDebugSettingsFieldOrGetInitialValue';
import writeDebugSettingsField from './writeDebugSettingsField';

const useStore = defineStore('debugSettings', {
  actions: {
    initSettings() {
      if (this.isInitialized) {
        return;
      }

      this.isInitialized = true;

      const settingsFieldKeys = Object.keys(this.settings) as (keyof DebugSettings)[];

      for (const settingsFieldKey of settingsFieldKeys) {
        const gotValue = readDebugSettingsFieldOrGetInitialValue(settingsFieldKey);

        this.settings = {
          ...this.settings,
          [settingsFieldKey]: gotValue,
        };
      }
    },
    updateSettingsField<T extends keyof DebugSettings>(settingsFieldKey: T, value: DebugSettings[T]) {
      this.settings = {
        ...this.settings,
        [settingsFieldKey]: value,
      };

      writeDebugSettingsField(settingsFieldKey, value);
    },
  },
  state: (): {
    isInitialized: boolean;
    settings: DebugSettings;
  } => ({
    isInitialized: false,
    settings: getInitialDebugSettings(),
  }),
});

export default function useDebugSettings() {
  const store = useStore();

  store.initSettings();

  const settings = computed(() => store.settings);

  const updateSettingsField = <T extends keyof DebugSettings>(settingsFieldKey: T, value: DebugSettings[T]) => {
    store.updateSettingsField(settingsFieldKey, value);
  };

  return {
    settings,
    updateSettingsField,
  };
}
