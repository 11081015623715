import type DebugSettings from './DebugSettings';

import DataTableColorizationMode from '../../../data-table/data-table-colorization/models/DataTableColorizationMode';

export default function getInitialDebugSettings(): DebugSettings {
  return {
    colorizationMode: DataTableColorizationMode.COLORED_ROWS,
    isEnableLogging: false,
    isShowMonitorForDevelopersInMenu: false,
    isSyncSortingAndFiltersWithRouter: true,
    isWithVerticalBorders: false,
  };
}
