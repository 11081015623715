import type MayBePromise from '../types/MayBePromise';

import type { NavigationGuard } from 'vue-router';
import { useRouter } from 'vue-router';

export default function onBeforeEachOnce(
  guard: NavigationGuard,
  onBeforePassing?: () => MayBePromise<void>,
  onPassed?: () => MayBePromise<void>,
) {
  const router = useRouter();

  const stopBeforeEachHook = router.beforeEach(async (to, from, next) => {
    void onBeforePassing?.();

    await guard(to, from, next);

    stopBeforeEachHook();

    void onPassed?.();
  });
}
