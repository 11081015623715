import type { RouteRecordRaw } from 'vue-router';

const TrendsPage = async () => import('../../pages/TrendsPage.vue');

export default function getTrendsRoutes(): RouteRecordRaw[] {
  return [
    {
      component: TrendsPage,
      meta: {
        title: 'Trends',
        useIn: ['navigation-tabs'],
      },
      name: 'trends',
      path: '/trends',
    },
  ];
}
