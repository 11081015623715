import type { RouteRecordRaw } from 'vue-router';

import ReportSubjectType from './models/ReportSubjectType';

const ReportPage = async () => import('../../../pages/report/ReportPage.vue');
const ReportLeadsPage = async () => import('../../../pages/report/ReportLeadsPage.vue');

const availableReportSubjectTypes: ReportSubjectType[] = [
  ReportSubjectType.CAMPAIGN,
  ReportSubjectType.OFFER,
  ReportSubjectType.LANDING,
  ReportSubjectType.AFFILIATE_NETWORK,
  ReportSubjectType.ROTATION,
  ReportSubjectType.TRAFFIC_SOURCE,
];

export default function getReportRoutes(): RouteRecordRaw[] {
  return [
    {
      component: ReportLeadsPage,
      meta: {
        title: 'Report',
      },
      name: 'report-leads',
      path: `/report/leads/:subjectType(${availableReportSubjectTypes.join('|')})/:subjectIds(\\d+)+`,
    },
    {
      component: ReportPage,
      meta: {
        title: 'Report',
      },
      name: 'report',
      path: `/report/:subjectType(${availableReportSubjectTypes.join('|')})/:subjectIds(\\d+)+`,
    },
  ];
}
