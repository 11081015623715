import Toast from 'vue-toastification';

import style from './toastNotificationStyles.module.scss';

import 'vue-toastification/dist/index.css';

export default function createToast() {
  return [
    Toast,
    {
      containerClassName: style.container,
      maxToasts: 3,
      transition: 'Vue-Toastification__fade',
    },
  ] as const;
}
