import type { RouteRecordRaw } from 'vue-router';

import RouteName from '../../router/RouteName';

const FilesPage = async () => import('../../pages/files/FilesPage.vue');

export default function getFilesRoutes(): RouteRecordRaw[] {
  return [
    {
      component: FilesPage,
      meta: {
        title: 'Files',
        useIn: ['navigation-tabs'],
      },
      name: RouteName.FILES,
      path: '/files',
    },
  ];
}
