import type MayBePromise from '../../../lib/types/MayBePromise';

import { onBeforeUnmount } from 'vue';

import { isObject } from 'lodash-es';

import getApiClient from '../../../shared/api/api-client/getApiClient';

const UNAUTHORIZED_HTTP_CODE = 401;

function isResponseDataHasAuthenticationErrorMessage(responseData: unknown): boolean {
  if (!isObject(responseData)) {
    return false;
  }
  if (!('message' in responseData) || typeof responseData.message !== 'string') {
    return false;
  }
  return responseData.message.includes('AUTHENTICATION_ERROR');
}

export default function onGotUnauthorizedApiResponse(callback: () => MayBePromise<void>) {
  const client = getApiClient();

  let queue = Promise.resolve();

  const stopInterceptor = client.useResponseInterceptor(async (response, requestUrl) => {
    if (requestUrl?.includes('/logout')) {
      return response;
    }

    if (response.ok || response.status === 0) {
      return response;
    }

    const json = await response.json();

    const isUnauthorized =
      response.status === UNAUTHORIZED_HTTP_CODE || isResponseDataHasAuthenticationErrorMessage(json);

    if (!isUnauthorized) {
      return response;
    }

    queue = queue.then(async () => callback());

    return response;
  });

  onBeforeUnmount(stopInterceptor);
}
