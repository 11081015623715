import { createPinia } from 'pinia';
import { createApp } from 'vue';

import App from './app/App.vue';
import createTanstackQuery from './lib/tanstack-query/createTanstackQuery';
import createToast from './lib/toast-notification/createToast';
import createRouter from './router/createRouter';

const initApp = () => {
  const app = createApp(App);

  const ROUTER_PREFIX = 'panel';

  if (!globalThis.location.pathname.startsWith(`/${ROUTER_PREFIX}/`)) {
    globalThis.history.replaceState(
      globalThis.history.state,
      '',
      `/${ROUTER_PREFIX}${globalThis.location.search}${globalThis.location.hash}`,
    );
  }

  app.use(createRouter(ROUTER_PREFIX));

  app.use(createPinia());

  app.use(...createToast());

  app.use(...createTanstackQuery());

  app.mount('.main');
};

initApp();
