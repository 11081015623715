import type DebugSettings from './DebugSettings';

import { computed } from 'vue';

import useDebugSettings from './useDebugSettings';

export default function useDebugSettingsField<T extends keyof DebugSettings>(fieldKey: T) {
  const { settings, updateSettingsField } = useDebugSettings();

  const value = computed<DebugSettings[T]>(() => settings.value[fieldKey]);

  const updateValue = (nextValue: DebugSettings[T]) => {
    updateSettingsField(fieldKey, nextValue);
  };

  return {
    updateValue,
    value,
  };
}
