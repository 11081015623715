import signIn from '../authentication/api/signIn';

const DEMO_CREDENTIALS = {
  password: 'binomdemo',
  username: 'demo',
};

export default async function signInAsDemo() {
  return signIn(DEMO_CREDENTIALS.username, DEMO_CREDENTIALS.password);
}
