import type CrudAction from '../crud/CrudAction';

import type { RouteRecordRaw } from 'vue-router';

import CrudActionType from '../crud/CrudActionType';

const TrafficSourcesPage = async () => import('../../pages/TrafficSourcesPage.vue');

export default function getTrafficSourcesRoutes(): RouteRecordRaw[] {
  return [
    {
      component: TrafficSourcesPage,
      meta: {
        title: 'Traffic sources',
        useIn: ['navigation-tabs'],
      },
      name: 'traffic-sources',
      path: '/traffic-sources',
      props: () => ({
        crudAction: null,
      }),
    },
    {
      component: TrafficSourcesPage,
      name: 'create-traffic-source',
      path: '/traffic-sources/create',
      props: (): {
        crudAction: CrudAction;
      } => ({
        crudAction: {
          type: CrudActionType.CREATE,
        },
      }),
    },
    {
      component: TrafficSourcesPage,
      name: 'edit-traffic-source',
      path: '/traffic-sources/edit/:id',
      props: (
        route,
      ): {
        crudAction: CrudAction;
      } => ({
        crudAction: {
          id: route.params.id as string,
          type: CrudActionType.EDIT,
        },
      }),
    },
    {
      component: TrafficSourcesPage,
      name: 'clone-traffic-source',
      path: '/traffic-sources/clone/:id',
      props: (
        route,
      ): {
        crudAction: CrudAction;
      } => ({
        crudAction: {
          id: route.params.id as string,
          type: CrudActionType.CLONE,
        },
      }),
    },
  ];
}
