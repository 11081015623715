import DOMPurify from 'dompurify';

export default function extractTextFromHtmlString(value: string): string {
  return DOMPurify.sanitize(value, {
    USE_PROFILES: {
      html: false,
      mathMl: false,
      svg: false,
      svgFilters: false,
    },
  });
}
