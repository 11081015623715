import type UserRoleDto from '../UserRoleDto';

import UserRole from '../../../../users/models/UserRole';

const userRoleDtoToUserRoleMapping: Record<UserRoleDto, UserRole> = {
  admin: UserRole.ADMIN,
  super_admin: UserRole.SUPER_ADMIN,
  user: UserRole.USER,
};

export default function transformUserRoleFromDto(userRoleDto: UserRoleDto): UserRole {
  return userRoleDtoToUserRoleMapping[userRoleDto];
}
