import { defineStore } from 'pinia';
import { onBeforeUnmount } from 'vue';

import { without } from 'lodash-es';

declare const window: {
  history: {
    pushState: {
      __isWrapped?: boolean;
    } & History['pushState'];
    replaceState: {
      __isWrapped?: boolean;
    } & History['pushState'];
  } & History;
} & Window;

const originalPushState = window.history.pushState;
const originalReplaceState = window.history.replaceState;

const useLocationChangedHandlersStore = defineStore('locationChangedHandlers', {
  actions: {
    addHandler(handler: () => void) {
      this.handlers.push(handler);

      this.wrapOrUnwrapHistoryApiMethods();
    },
    callAllHandlers() {
      for (const handler of this.handlers) {
        handler();
      }
    },
    removeHandler(handler: () => void) {
      this.handlers = without(this.handlers, handler);

      this.wrapOrUnwrapHistoryApiMethods();
    },
    /**
     * @description деактивирует расширение с вызовом хендлеров
     */
    unwrapHistoryApiMethods() {
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    },
    /**
     * @description расширяет оригинальные pushState и replaceState методы History API вызовом всех хендлеров из списка
     */
    wrapHistoryApiMethods() {
      this.wrapPushStateMethod();
      this.wrapReplaceStateMethod();
    },
    /**
     * @description когда нет хендлеров, нет необходимости в расширении для вызова хендлеров,
     *              поэтому в pushState и replaceState возвращаются оригинальные методы.
     *              когда хендлеры есть, методы нужно расширить
     *
     */
    wrapOrUnwrapHistoryApiMethods() {
      if (this.handlers.length === 0) {
        this.unwrapHistoryApiMethods();
        return;
      }
      this.wrapHistoryApiMethods();
    },
    /**
     * @description расширяет оригинальный pushState метод History API вызовом всех хендлеров из списка
     */
    wrapPushStateMethod() {
      // чтобы не расширять уже расширенный метод, проверяем флаг.
      // у расширенного метода он будет, у оригинального - нет
      if (window.history.pushState.__isWrapped) {
        return;
      }

      const callAllHandlers = this.callAllHandlers.bind(this);

      window.history.pushState = function (...args: Parameters<typeof originalPushState>) {
        originalPushState.call(this, ...args);

        callAllHandlers();
      };

      window.history.pushState.__isWrapped = true;
    },
    /**
     * @description расширяет оригинальный replaceState метод History API вызовом всех хендлеров из списка
     */
    wrapReplaceStateMethod() {
      if (window.history.replaceState.__isWrapped) {
        return;
      }

      const callAllHandlers = this.callAllHandlers.bind(this);

      window.history.replaceState = function (...args: Parameters<typeof originalReplaceState>) {
        originalReplaceState.call(this, ...args);

        callAllHandlers();
      };

      window.history.replaceState.__isWrapped = true;
    },
  },
  state: (): {
    handlers: (() => void)[];
  } => ({
    handlers: [],
  }),
});

export default function onLocationChangedByHistoryApi(callback: () => void) {
  const locationChangedHandlersStore = useLocationChangedHandlersStore();

  locationChangedHandlersStore.addHandler(callback);

  onBeforeUnmount(() => {
    locationChangedHandlersStore.removeHandler(callback);
  });
}
