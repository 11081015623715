import { defineStore } from 'pinia';
import { ref } from 'vue';

import anime from 'animejs';

import sleep from '../../lib/awaiters/sleep';

export default defineStore('router-navigation-progress', () => {
  const progress = ref(0);
  const isPending = ref(false);

  const animeInstance = ref<Pick<anime.AnimeInstance, 'pause'> | null>(null);

  const MAX_PROGRESS_AFTER_ANIMATION = 0.75;

  const LOADING_ANIMATION_DURATION = 800;

  const startLoadingAnimation = async () => {
    return new Promise<void>((resolve) => {
      isPending.value = true;
      progress.value = 0;

      animeInstance.value = anime({
        complete: () => {
          resolve();
        },
        duration: LOADING_ANIMATION_DURATION,
        easing: 'easeInCubic',
        targets: progress,
        value: MAX_PROGRESS_AFTER_ANIMATION,
      });
    });
  };

  const END_OF_LOADING_ANIMATION_DURATION = 400;

  const startEndOfLoadingAnimation = async () => {
    return new Promise<void>((resolve) => {
      animeInstance.value?.pause();

      animeInstance.value = anime({
        complete: () => {
          isPending.value = false;
          progress.value = 0;

          resolve();
        },
        duration: END_OF_LOADING_ANIMATION_DURATION,
        easing: 'easeInCubic',
        targets: progress,
        value: 1,
      });
    });
  };

  const isStarted = ref(false);

  const start = async () => {
    isStarted.value = true;

    // если переход происходит достаточно быстро, то показывать прогрессбар не нужно, поэтому ждём 300мс
    // флаг isStarted обнуляется функцией stop, поэтому если переход завершился, startLoadingAnimation не будет выполнена

    await sleep(300);

    if (!isStarted.value) {
      return;
    }

    await startLoadingAnimation();
  };

  const stop = async () => {
    if (!isStarted.value) {
      return;
    }

    isStarted.value = false;

    await startEndOfLoadingAnimation();
  };

  return {
    isPending,
    progress,
    start,
    stop,
  };
});
