export default function useLocalStorageSync<T>({
  getDefaultValue,
  key: keyOrKeyGetter,
}: {
  getDefaultValue: () => T;
  key: (() => string) | string;
}) {
  const getKey = typeof keyOrKeyGetter === 'function' ? keyOrKeyGetter : () => keyOrKeyGetter;

  return {
    clearValueInLocalStorage: () => {
      localStorage.removeItem(getKey());
    },
    readValueFromLocalStorage: (): T => {
      const read = localStorage.getItem(getKey());

      if (read === null) {
        return getDefaultValue();
      }
      return JSON.parse(read) as T;
    },
    writeValueToLocalStorage: (value: T) => {
      localStorage.setItem(getKey(), JSON.stringify(value));
    },
  };
}
