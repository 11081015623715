enum MonitorForDevelopersRouteName {
  BUILD_INFO = 'build-info',
  CLICKER = 'clicker',
  DEBUG_SETTINGS = 'debug-settings',
  MAIN = 'main',
  MESSAGES = 'messages',
  SEED = 'seed',
}

export default MonitorForDevelopersRouteName;
